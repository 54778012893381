export default [
    {
        name: 'product.public',
        path: '/products/:productId/public',
        component: () => import('./views/ProductSinglePublic.vue'),
        meta: {
            public: true,
            layout: 'public',
            layoutOptions: {
                hideLinks: true,
            }
        }
    }
]
