/**
 * @type {import('vue-router').RouteConfig[]}
 */
export default [
    {
        name: 'promoter.dashboard',
        path: '/promoters/:userId/dashboard',
        component: () => import('./PromoterDashboard.vue'),
        redirect: { name: 'promoter.dashboard.proposals' },
        children: [
            {
                name: 'promoter.dashboard.proposals',
                path: '/promoters/:userId/dashboard/proposals',
                component: () => import('./views/PromoterProposalList.vue'),
            },
            {
                name: 'promoter.dashboard.proposal-single',
                path: '/promoters/:userId/dashboard/proposals/:proposalId',
                component: () => import('./views/PromoterProposalSingle.vue'),
            },
            {
                name: 'promoter.dashboard.commission-list',
                path: '/promoters/:userId/dashboard/commisions',
                component: () => import('@/modules/commission/views/CommisionUserCommissionList.vue'),
            }

        ]
    }
]
