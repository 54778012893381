import axios from "axios";
import { emitToastr } from "./Utils";

const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:3005/admin" : (process.env.VUE_APP_API_URL || 'https://vendas.evogear.com.br/admin');

class Api {
    login = async (email, password, remember) => {
        const endpoint = "/auth";

        let result = await this.authPost(endpoint, { email, password, remember });

        if (result && result.token && result.user) {
            window.localStorage.setItem("token", result.token);
            delete result.token;
            window.localStorage.setItem("user", JSON.stringify(result.user));
            result.login_ok = true;
        }

        return result;
    };

    logout = async () => {
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("token");
        window.location.href = "/login";
    };

    getUser = () => {
        let user = window.localStorage.getItem("user");
        try {
            user = JSON.parse(user);
            return user;
        } catch {
            return {};
        }
    };

    getToken = () => {
        return window.localStorage.getItem("token") || "";
    };

    getRemoteUser = async () => {
        const user = await this.authPost("/get-user", {}, {});

        if (user && user._id) {
            window.localStorage.setItem("user", JSON.stringify(user));
            return user;
        }
        else {
            this.logout();
        }
    };

    uploadProfilePicture = async (image) => {
        const resp = await this.authPost("/upload-profile-picture", { image: image }, { multipart: true, formData: true }); //ESSE TEM ESSAS OPÇÕES PORQUE ESTÁ ENVIANDO UMA IMAGEM AO INVÉS DE UM JSON
        return resp;
    };

    uploadImage = async (image) => {
        const resp = await this.authPost("/upload-image", { image: image }, { multipart: true, formData: true });
        return resp;
    };

    uploadFile = async (file) => {
        return await this.authPost("/upload-file", { file: file }, { multipart: true, formData: true });
    }

    uploadOrderProductImage(image) {
        return this.authPost("/upload-order-product-image", { image: image }, { multipart: true, formData: true });
    }

    createUser = async (payload) => {
        return await this.authPost("/create-user", payload, {});
    }

    updateUser = async (payload) => {
        return await this.authPost("/update-user", payload, {});
    }

    getUsers = async (payload) => {
        return await this.authPost("/get-users", payload);
    }

    getUserById = async (id) => {
        return await this.authPost("/get-user-by-id", { id }, {});
    }

    getSellers = async () => {
        return await this.authPost("/get-sellers", {}, {});
    }

    getAssemblers = async () => {
        return await this.authPost("/get-assemblers", {}, {});
    }

    recoveryPassword = async (email) => {
        const resp = await this.authPost("/send-password-recovery", { email: email }, {});
        return resp;
    };

    changeUserPassword = async (password, old_password) => {
        const resp = await this.authPost("/change-user-password", { password, old_password }, {});
        return resp;
    };

    checkErrorsWithStatusCode200 = (data) => {
        if (data.error) {
            emitToastr("error", data.message || "Erro não identificado!", "Erro");
        }

        return data;
    };

    getProducts = async () => {
        return await this.authPost("/get-products");
    }

    getProductById = async (id) => {
        return await this.authPost("/get-product-by-id", { id });
    }

    getSubcategoryProductCode = async (subcategory, pre_code) => {
        return await this.authPost("/get-subcategory-product-count", { subcategory, pre_code });
    }

    createProduct = async (product) => {
        return await this.authPost("/create-product", { object: product });
    }

    updateProduct = async (product) => {
        return await this.authPost("/update-product", { object: product });
    }

    createOrUpdateSupplier = async (object) => {
        return await this.authPost("/create-or-update-supplier", { object: object });
    }

    getSuppliers = async () => {
        return await this.authPost("/get-suppliers", {});
    }

    getSupplierById = async (id) => {
        return await this.authPost("/get-supplier-by-id", { id });
    }

    getMinimumMarkups = async () => {
        return await this.authPost("/get-minimum-markups");
    }


    createOrUpdateMinimumMarkup = async (minimumMarkup) => {
        return await this.authPost("/create-or-update-minimum-markup", { object: minimumMarkup });
    }

    getSettings = async () => {
        return await this.authPost("/get-settings");
    }

    createOrUpdateSetting = async (setting) => {
        return await this.authPost("/create-or-update-setting", { object: setting });
    }

    getProposals = async (filter) => {
        return await this.authPost("/get-proposals", filter);
    }

    getV2Proposals = async (filter) => {
        return await this.authPost("/v2/get-proposals", filter);
    }

    getProposalById = async (id) => {
        return await this.authPost("/get-proposal-by-id", { id });
    }

    createProposal = async (payload) => {
        return await this.authPost("/create-proposal", payload);
    }

    updateProposal = async (proposal) => {
        return await this.authPost("/update-proposal", { object: proposal });
    }

    createProposalVersion = async (payload) => {
        return await this.authPost("/create-proposal-version", payload);
    }

    createProposalVersionPreview = async (payload) => {
        return await this.authPost("/create-proposal-version-preview", payload);
    }

    updateProposalVersion = async (payload) => {
        return await this.authPost("/update-proposal-version", payload);
    }

    updateProposalVersionItems = async (proposal_id, version, items) => {
        return await this.authPost("/update-proposal-version-items", { proposal_id, version, items });
    }

    approveProposalVersion = async (proposal_id, object) => {
        return await this.authPost("/approve-proposal-version", { proposal_id, object });
    }

    createProposalCopy = async (payload) => {
        return await this.authPost("/create-proposal-copy", payload);
    }

    getLastDollar = async () => {
        return await this.authPost("/get-last-dolar");
    }

    getCustomers = async (payload) => {
        return await this.authPost("/get-customers", payload);
    }

    getCustomerById = async (id, payload) => {
        return await this.authPost("/get-customer-by-id", { id, ...payload });
    }

    createCustomer = async (customer) => {
        return await this.authPost("/create-customer", { object: customer });
    }

    updateCustomer = async (customer) => {
        return await this.authPost("/update-customer", { object: customer });
    }

    getOrders = async () => {
        return await this.authPost("/get-orders");
    }

    getOrderById = async (id, options) => {
        return await this.authPost("/get-order-by-id", { id, ...options });
    }

    createOrder = async (object) => {
        return await this.authPost("/create-order", { object });
    }

    updateOrder = async (object) => {
        return await this.authPost("/update-order", { object });
    }

    updateOrderProposal = async (payload) => {
        return await this.authPost("/update-order-proposal", payload);
    }

    deleteOrder = async (id) => {
        return await this.authPost("/delete-order", { id });
    }

    exportProducts = async () => {
        const endpoint = '/export-products';
        const result = await this.authPost(endpoint, {}, { arraybuffer: true });
        return result;
    }

    exportOrderToExcel = async (products) => {
        const endpoint = '/export-order-to-excel';
        const result = await this.authPost(endpoint, { products }, { arraybuffer: true });
        return result;
    }

    getCategories = async () => {
        return await this.authPost("/get-categories");
    }

    getCategoryById = async (id) => {
        return await this.authPost("/get-category-by-id", { id });
    }

    createCategory = async (category) => {
        return await this.authPost("/create-category", { object: category });
    }

    updateCategory = async (category) => {
        return await this.authPost("/update-category", { object: category });
    }

    deleteCategory = async (id) => {
        return await this.authPost("/delete-category", { id });
    }

    getSubcategories = async () => {
        return await this.authPost("/get-subcategories");
    }

    createSubcategory = async (subcategory) => {
        return await this.authPost("/create-subcategory", { object: subcategory });
    }

    updateSubcategory = async (subcategory) => {
        return await this.authPost("/update-subcategory", { object: subcategory });
    }

    deleteSubcategory = async (id) => {
        return await this.authPost("/delete-subcategory", { id });
    }

    getProductGroups = async () => {
        return await this.authPost("/get-product-groups");
    }

    getProductGroupById = async (id) => {
        return await this.authPost("/get-product-group-by-id", { id });
    }

    createProductGroup = async (payload) => {
        return await this.authPost("/create-product-group", payload);
    }

    updateProductGroup = async (payload) => {
        return await this.authPost("/update-product-group", payload);
    }

    deleteProductGroup = async (id) => {
        return await this.authPost("/delete-product-group", { id });
    }

    getCustomerProducts = async (payload) => {
        return await this.authPost("/get-customer-products", payload);
    }

    getCustomerProductById = async (payload) => {
        return await this.authPost("/get-customer-product-by-id", payload);
    }

    updateCustomerProduct = async (payload) => {
        return await this.authPost("/update-customer-product", payload);
    }

    createOrUpdateCustomerUser = async (payload) => {
        return await this.authPost("/create-or-update-customer-user", payload);
    }

    getOrderProposalsByCustomer = async (customer_id) => {
        return await this.authPost("/get-order-proposals-by-customer", { customer_id });
    }

    getTickets = async () => {
        return await this.authPost("/get-tickets");
    }

    getTicket = async (id) => {
        return await this.authPost("/get-ticket", { id });
    }

    createTicket = async (payload) => {
        return await this.authPost("/create-ticket", payload);
    }

    updateTicket = async (payload) => {
        return await this.authPost("/update-ticket", payload);
    }

    getCommissionSettings = async () => {
        return await this.authPost("/get-commission-settings");
    }

    getCommissionSettingById = async (payload) => {
        return await this.authPost("/get-commission-setting-by-id", payload);
    }

    createCommissionSetting = async (payload) => {
        return await this.authPost("/create-commission-setting", payload);
    }

    updateCommissionSetting = async (payload) => {
        return await this.authPost("/update-commission-setting", payload);
    }

    deleteCommissionSetting = async (payload) => {
        return await this.authPost("/delete-commission-setting", payload);
    }

    getCommissionReports = async (payload) => {
        return await this.authPost("/get-commission-reports", payload);
    }

    getCommissionReportById = async (payload) => {
        return await this.authPost("/get-commission-report-by-id", payload);
    }

    getCommissionReportPreview = async (payload) => {
        return await this.authPost("/get-commission-report-preview", payload);
    }

    createCommissionReport = async (payload) => {
        return await this.authPost("/create-commission-report", payload);
    }

    updateCommissionReport = async (payload) => {
        return await this.authPost("/update-commission-report", payload);
    }

    deleteCommissionReport = async (payload) => {
        return await this.authPost("/delete-commission-report", payload);
    }

    getCommissionReportItemsByUser = async (payload) => {
        return await this.authPost("/get-commission-report-items-by-user", payload);
    }

    getSellerCommissionStats = async (payload) => {
        return await this.authPost("/get-seller-commission-stats", payload);
    }

    getAllEducationContent = async () => {
        return await this.authPost("/get-all-education-content");
    }

    getAllEducationContentCategories = async () => {
        return await this.authPost("/get-all-education-content-categories");
    }

    getVisibleEducationContent = async () => {
        return await this.authPost("/get-visible-education-content");
    }

    createOrUpdateEducationContent = async (payload) => {
        return await this.authPost("/create-or-update-education-content", payload);
    }
    createOrUpdateEducationContentCategory = async (payload) => {
        return await this.authPost("/create-or-update-education-content-category", payload);
    }


    get = (url) =>
        axios
            .get(`${API_URL}${url}`)
            .then((response) => this.checkErrorsWithStatusCode200(response.data))
            .catch(() => {
                emitToastr("error", "Erro genérico de servidor!", "Erro");
            });

    authGet = (url, data) =>
        axios
            .get(`${API_URL}${url}`, { headers: { auth: this.getToken() }, params: data })
            .then((response) => this.checkErrorsWithStatusCode200(response.data))
            .catch((err) => {
                if (err && err.response && err.response.status === 401) {
                    emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
                    if (err.response.data && err.response.data.logoff === true) this.logout();
                } else {
                    if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
                    console.log("Erro genérico de servidor");
                }
            });

    authPost = (url, data, options) => {
        const headers = { auth: this.getToken() };

        if (options) {
            if (options.multipart) {
                headers["Content-Type"] = "multipart/form-data";
            }
            if (options.formData) {
                let formData = new FormData();
                for (let key in data) {
                    formData.append(key, data[key]);
                }
                data = formData;
            }
        }
        return axios
            .post(`${API_URL}${url}`, data, { headers, responseType: options && options.arraybuffer ? 'arraybuffer' : undefined })
            .then((response) => this.checkErrorsWithStatusCode200(response.data))
            .catch((err) => {

                const response = err.response;
                const data = err.response?.data;
                const error = err.response?.data?.error || true;
                const message = err.response?.data?.message || "Erro genérico de servidor!";
                const status = err.response?.status;

                if (status === 401) {
                    emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");

                    if (data.logoff === true) this.logout();

                } else if (error && message) {
                    emitToastr("error", message);
                } else if (response && status) {
                    emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
                } else {
                    emitToastr("error", "Erro genérico de servidor!", "Erro");
                }

                return {
                    error: true,
                    message: message,
                    status: status
                }
            });
    };
}

export default new Api();
